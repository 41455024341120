import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  connectWalletAsync, newWalletConnected,
  SimpleAsyncState, walletDisconnected
} from '@cyberpnk/component-library';
import {
  createGld, getMyGldContract, CreateGldParams, GetMyGldContractParams
} from './glderApi';

export interface GlderState {
  createGld: SimpleAsyncState<void>;
  getMyGldContract: SimpleAsyncState<string>;
}

const initialState: GlderState = {
  createGld: {
    loading: false,
  },
  getMyGldContract: {
    loading: false,
  },
};

export const createGldAsync = createAsyncThunk(
  'glder/createGld',
  async (params: CreateGldParams, thunkAPI) => {
    return await createGld(params);
  }
);

export const getMyGldContractAsync = createAsyncThunk(
  'glder/getMyGldContract',
  async (params: GetMyGldContractParams, thunkAPI) => {
    return await getMyGldContract(params);
  }
);

export const glderSlice = createSlice({
  name: 'glder',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createGldAsync.pending, (state) => {
        state.createGld.loading = true;
        state.createGld.error = undefined;
      })
      .addCase(createGldAsync.fulfilled, (state, action) => {
        state.createGld.loading = false;
      })
      .addCase(createGldAsync.rejected, (state, action) => {
        state.createGld.loading = false;
        state.createGld.error = "There was an error with the transaction"
      })
      .addCase(getMyGldContractAsync.pending, (state) => {
        state.getMyGldContract.loading = true;
        state.getMyGldContract.error = undefined;
      })
      .addCase(getMyGldContractAsync.fulfilled, (state, action) => {
        state.getMyGldContract.loading = false;
        state.getMyGldContract.response = action.payload;
      })
      .addCase(getMyGldContractAsync.rejected, (state, action) => {
        state.getMyGldContract.loading = false;
        state.getMyGldContract.error = "There was an error with the transaction"
      })
      .addCase(connectWalletAsync.pending, (state) => {
        state.createGld = initialState.createGld;
        state.getMyGldContract = initialState.getMyGldContract;
      })
      .addCase(newWalletConnected, (state) => {
        state.createGld = initialState.createGld;
        state.getMyGldContract = initialState.getMyGldContract;
      })
      .addCase(walletDisconnected, (state) => {
        state.createGld = initialState.createGld;
        state.getMyGldContract = initialState.getMyGldContract;
      })
  },
});


export const selectCreateGld = (state: any) => state.glder.createGld;
export const selectGetMyGldContract = (state: any) => state.glder.getMyGldContract;

export const glderReducer = glderSlice.reducer;

export default glderSlice.reducer;
