import { ofType, StateObservable } from "redux-observable";
import { filter, map, switchMap } from "rxjs/operators";
import { getMyGldContractAsync, createGldAsync } from "./glderSlice";
import { RootState } from "../../app/store";

export const glderEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(createGldAsync.fulfilled),
  map(() => state$.value.glder.createGld.request),
  filter(request => !!request),
  switchMap(({ nftContract }) => [
    getMyGldContractAsync({nftContract}),
  ]),
);
