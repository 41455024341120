import { callAndWaitForEvent, getConnectedAddress } from "@cyberpnk/component-library";
import {
  getGlderContract,
} from "../../app/common";

declare global {
  interface Window { ethereum: any; }
}

export interface CreateGldParams {
  name: string, symbol: string, nftContract: string, gldPerNft: string
}

export const createGld = async ({ name, symbol, nftContract, gldPerNft }: CreateGldParams) => {
  const gldContractMinter = await getConnectedAddress();
  return callAndWaitForEvent(getGlderContract, "createGld(string,string,address,uint)", [name, symbol, nftContract, gldPerNft], "CreateGld", { nftContract, gldContractMinter });
}

export interface GetMyGldContractParams { nftContract: string };

export const getMyGldContract = async ({ nftContract }: GetMyGldContractParams): Promise<string> => {
  const user = await getConnectedAddress();
  const glder = await getGlderContract();
  const contractAddress = await glder.methods.myGldContract(user, nftContract).call();
  return contractAddress;
}
