import React from 'react';
import styles from './App.module.css';
import {
  Network, Footer,
  EtherscanLink
} from '@cyberpnk/component-library';
import packageJson from '../package.json';
import addresses from "./contract-data/contract-addresses.json";
import apegldImg from "./apegld-min.png";
import apegldTxImg from "./apegld_tx-min.png";
import apegldContractImg from "./apegld_contract-min.png";

const { version } = packageJson;

function App() {
  const network = Network.Mainnet;

  return (
    <div className={styles.App}>
      <div className={styles.main}>
        <h1>Glder</h1>
        <h2>Make ERC20 tokens for NFT collections</h2>
        <p>There is money to be made giving money away to NFT holders. But it's tedious, and you can make an innocent mistake, like printing yourself too much money.</p>
        <p>With this turn-key standardized solution you can generate your own contract, ready in etherescan for the people to sample from your boundless generosity, with a conveniently tasteful and modest pre-mined quantity for yourself and myself.</p>
        <p>Just add an ERC721 contract address to the mix, and you're free to integrate your new token with everything you do!  Hope that feels right.</p>
        <hr></hr>
        <h2>But how??</h2>
        <ul>
          <li>Head over to <EtherscanLink address={addresses[network]?.Glder} network={network}>the contract in etherscan</EtherscanLink></li>
          <li>
            Use the "createGld" function of the contract, with the following parameters. Let's make some BAYC gold!
            <ul>
              <li>name: "Ape Gold"</li>
              <li>symbol: "APEGLD"</li>
              <li>nftContract: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D"</li>
              <li>gldPerNft: "10000"</li>
            </ul>
          </li>
          <li>
            This looks like this:
            <img className={styles.screenshot} alt="APEGLD parameters" src={apegldImg} />
          </li>
          <li>Send the transaction, click the "View your transaction" button, and wait for it to go through.</li>
          <li>
            Look at the contract you minted here:
            <img className={styles.screenshot} alt="APEGLD tx" src={apegldTxImg} />
          </li>
          <li>
            Open that contract and you'll see this:
            <img className={styles.screenshot} alt="APEGLD contract" src={apegldContractImg} />
          </li>
          <li>You can use the claim functions to claim 10k APEGLD for each ape you hold.  If the NFT contract is ERC721Enumerable, you can even use claimAll to have the contract work out the IDs for you and do all the work.</li>
          <li>Now anybody with an ape can go to this contract and mint themselves 10k APEGLD per ape.  Just share the contract with them. Cool, right?</li>
          <li>And if you look at the holders tab, or in your wallet, you'll see that you've been minted automatically ten times the NFT allotment (so in this case 100k) for your troubles.  And so was I.</li>
          <li>Now you can just go ahead and integrate APEGLD in everything you do! Good luck!</li>
        </ul>
      </div>
      <div className={styles.footer}>
        <Footer version={version} contractAddress={addresses[network]?.Glder} openSeaCollection={""} network={network} />
      </div>
    </div>
  );
}

export default App;
